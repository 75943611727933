import {
  Button,
  Col,
  Form,
  Input,
  Row,
  notification,
  Divider,
  Checkbox,
  Drawer,
  Modal,
  InputNumber,
  Select,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faStar,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState, useRef, useCallback } from "react";
import $axios from "../../services/axios";
import Slider from "react-slick";
import RoomItem from "./RoomItem";
import _ from "lodash";
import dayjs from "dayjs";
import { MEAL_TYPE } from "../../helpers/const";
import axios from "axios";

const ChooseHotel = ({
  hotelSelected,
  open,
  setOpenChooseHotel,
  setHotelSelected,
  addPrice = 0,
  formSearch,
  filterSearch,
}) => {
  const [openViewMap, setOpenViewMap] = useState(false);
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [expandedDes, setExpandedDes] = useState(false);
  const [roomSelected, setRoomSelected] = useState(null);
  const [rateSelected, setRateSelected] = useState(null);
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherTxt, setVoucherTxt] = useState("");
  const [loadingBooking, setLoadingBooking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [invoice, setInvoice] = useState(false);
  const [invoiceCode, setInvoiceCode] = useState("");
  const [adtChoosed, setAdtChoosed] = useState(null);
  const [isPersonChecking, setIsPersonChecking] = useState(false);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  useEffect(() => {
    if (isPersonChecking) {
      form.setFieldsValue({
        first_name_adt: form.getFieldValue(`contactFirstName`),
        last_name_adt: form.getFieldValue(`contactLastName`),
      });
    }
  }, [isPersonChecking]);

  const toggleExpand = () => {
    setExpandedDes((prev) => !prev);
  };

  const applyVoucher = async (e) => {
    if (e) {
      try {
        const res = await $axios.$get(`/voucher/getVoucher?voucherCode=${e}`);
        if (res.data.data.status === 1) {
          setVoucherValue(res.data.data.amount);
          setVoucherCode(e);
          setVoucherTxt(
            `<div class="text-[14px] ">Áp dụng mã giảm giá thành công! <span class="text-green-600 text-[16px]">
      ${res.data.data.amount.toLocaleString("vi-VN")} đ</span></div>`
          );
        } else {
          setVoucherTxt(
            `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
          );
          setVoucherValue(0);
          setVoucherCode("");
        }
      } catch (error) {
        setVoucherTxt(
          `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
        );
        setVoucherValue(0);
        setVoucherCode("");
      } finally {
      }
    } else {
      setVoucherTxt(
        `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
      );
      setVoucherValue(0);
      setVoucherCode("");
    }
  };

  const handleBooking = async (values) => {
    setLoadingBooking(true);
    try {
      let occupancies = [];
      // _.forIn(values, (value, key) => {
      //   if (
      //     key.includes("first_name_adt_") ||
      //     key.includes("first_name_chd_")
      //   ) {
      //     const firstName = value;
      //     const lastName =
      //       values[
      //         `${key.replace(
      //           key.includes("_adt_") ? "first_name_adt_" : "first_name_chd_",
      //           key.includes("_adt_") ? "last_name_adt_" : "last_name_chd_"
      //         )}`
      //       ];
      //     const roomIndex =
      //       form.getFieldValue("numRooms") > 1
      //         ? values[
      //             `${key.replace(
      //               key.includes("first_name_adt_")
      //                 ? "first_name_adt_"
      //                 : "first_name_chd_",
      //               key.includes("first_name_adt_")
      //                 ? "room_index_adt_"
      //                 : "room_index_chd_"
      //             )}`
      //           ]
      //         : 1;
      //     occupancies.push({
      //       Type: "AD",
      //       Age: 30,
      //       FirstName: firstName,
      //       LastName: lastName,
      //       FullName: firstName + " " + lastName,
      //       RoomIndex: roomIndex === 0 ? -1 : roomIndex,
      //     });
      //   }
      // });
      const body = {
        StayCheckIn: dayjs(formSearch.getFieldValue("departDate")).format(
          "YYYY-MM-DD"
        ),
        StayCheckOut: dayjs(formSearch.getFieldValue("returnDate")).format(
          "YYYY-MM-DD"
        ),
        HotelCode: hotelSelected.id,
        HotelName: hotelSelected.name,
        HotelAddress: hotelSelected.address.address,
        HotelStarNumber: hotelSelected.rating.starNumber,
        MealType: rateSelected.mealType,
        MealTypeName: MEAL_TYPE[rateSelected.mealType],
        BookingToken: rateSelected.bookingToken,
        RateCategory: rateSelected.rateCategory,
        RatePlanCode: rateSelected.ratePlanCode,
        RoomTypeName: roomSelected.roomTypeName,
        RoomTypeCode: roomSelected.roomTypeCode,
        NumberOfUnits: formSearch.getFieldValue("numRooms"),
        Nights: hotelSelected.nights,
        OccupancyPerRoom: 0, // values.occupancyPerRoom,
        ContactInfoLastName: values.contactLastName,
        ContactInfoFirstName: values.contactFirstName,
        ContactInfoEmail: values.contactEmail,
        ContactInfoPhone: values.contactPhone,
        VatMst: values.VatMst,
        VatFullName: values.VatFullName,
        VatEmail: values.contactEmail,
        VoucherCode: voucherCode,
        TotalAfterTax: rateSelected.rateAfterTaxTotal,
        TotalBeforeTax: rateSelected.rateBeforeTaxTotal,
        Amount: rateSelected.rateAfterTaxTotalNew - voucherValue,
        AmountOriginal: rateSelected.rateAfterTaxTotal,
        AmountDiscount: voucherValue,
        CancellationPolicies: rateSelected.cancellationPolicies.map((can) => {
          return {
            Penalty: can.penalty,
            Deadline: can.deadline,
          };
        }),
        DailyRates: rateSelected.dailyRates.map((rate) => {
          return {
            PeriodStartDate: rate.periodStartDate,
            PeriodEndDate: rate.periodEndDate,
            RateBeforeTax: rate.rateBeforeTax,
            RateAfterTax: rate.rateAfterTax,
            MealCount: rate.mealCount,
          };
        }),
        Occupancies: _.range(
          1,
          formSearch.getFieldValue("numAdults") +
            formSearch.getFieldValue("numChilds") +
            1
        ).map((item, index) => {
          return {
            Type: "AD",
            Age: 30,
            FirstName: item === 1 ? values.first_name_adt : 'TO BE ADDED',
            LastName: item === 1 ? values.last_name_adt : 'TO BE ADDED',
            FullName: item === 1 ? `${values.first_name_adt + ' ' + values.last_name_adt}` : 'TO BE ADDED',
            RoomIndex: _.range(1, formSearch.getFieldValue("numRooms") + 1).includes(item) ? item :  _.sample(_.range(1, form.getFieldValue("numRooms") + 1)),
          };
        }),
      };
      const resPayment = await $axios.post("/payment/paymentRequired", body);
      if (resPayment.data.data.paymentUrl) {
        window.location.href = resPayment.data.data.paymentUrl;
      } else {
        setLoadingBooking(false);
        notification.error({
          status: "error",
          message: "Thất bại",
          description: "Đã có lỗi xảy ra! Vui lòng đặt chuyến khác",
        });
      }
    } catch (error) {
      setLoadingBooking(false);
    }
  };

  const CheckProductAvailability = async (rate, room) => {
    setLoading(true);
    try {
      const body = {
        StayCheckIn: dayjs(formSearch.getFieldValue("departDate")).format(
          "YYYY-MM-DD"
        ),
        StayCheckOut: dayjs(formSearch.getFieldValue("returnDate")).format(
          "YYYY-MM-DD"
        ),
        Hotels: [String(hotelSelected.id)],
        Nationality: filterSearch.country,
        LanguageCode: "vi",
        RatePlanCode: rate.ratePlanCode, // "151ab7f0825bc5dcad34:2",
        RoomTypeCode: room.roomTypeCode, // "88327"
        NumberOfUnits: formSearch.getFieldValue("numRooms"), //1,
        Occupancies: {
          Adults:
            formSearch.getFieldValue("numAdults") +
            formSearch.getFieldValue("numChilds"),
          Children: 0,
          Paxes: _.range(
            1,
            formSearch.getFieldValue("numAdults") +
              formSearch.getFieldValue("numChilds") +
              1
          ).map((item) => {
            return {
              Age: 30,
              Type: "AD",
            };
          }),
        },
        // Occupancies: {
        //   Adults: 2,
        //   Children: 0,
        //   Paxes: [
        //     {
        //       Age: 30,
        //       Type: "CH",
        //     },
        //   ],
        // },
      };
      const res = await $axios.$post(`/vn-travel/product-avail`, body);
      if (_.get(res, "data.data.hotels[0].status", "") === "Success") {
        const _room = _.find(
          _.get(res, "data.data.hotels[0].rooms", []),
          (r) => r.roomTypeCode === room.roomTypeCode
        );
        const _rate = _.find(
          _.get(_room, "rates", []),
          (r) => r.ratePlanCode === rate.ratePlanCode
        );
        _rate["rateAfterTaxTotalNew"] = _rate["rateAfterTaxTotal"] + addPrice;
        _rate["rateBeforeTaxTotalNew"] = _rate["rateBeforeTaxTotal"] + addPrice;
        setRoomSelected(_room);
        setRateSelected(_rate);
        setStep(2);
      } else {
        notification.warning({
          description:
            "Gói giá hiện tại không có sẵn ! Vui lòng tham khảo gói giá khác",
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };

  const debouncedCheckMst = useCallback(
    _.debounce(async (value, callback) => {
      try {
        const response = await axios.get(
          `https://api.vietqr.io/v2/business/${value}`
        );

        if (response.data && response.data.code === "00") {
          callback(null); // Gọi resolve() khi API thành công
          setInvoiceCode(response.data.data.shortName);
        } else {
          callback(new Error(response.data.desc)); // Gọi callback với lỗi nếu không hợp lệ
          setInvoiceCode("");
        }
      } catch (error) {
        callback(new Error("Không thể xác minh mã số thuế")); // Gọi callback với lỗi nếu có lỗi từ API
        setInvoiceCode("");
      }
    }, 2000), // Debounce 2s
    []
  );

  const handleValueChanges = (changeValues, allValues) => {
    if (isPersonChecking) {
      form.setFieldsValue({
        first_name_adt: form.getFieldValue(`contactFirstName`),
        last_name_adt: form.getFieldValue(`contactLastName`),
      });
    }
  };

  return (
    <>
      <Drawer
        title={step === 1 ? "1. CHỌN PHÒNG" : "2. THÔNG TIN KHÁCH HÀNG "}
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setOpenChooseHotel(false);
          setHotelSelected(null);
        }}
        className="drawer-no-padding"
        open={open}
        width="100%"
        footer={
          step === 2 && (
            <div className="flex justify-between gap-5 mt-5">
              <Button
                className="w-full h-[50px] bg-white  text-[#0064d2]"
                onClick={() => setStep(1)}
              >
                <FontAwesomeIcon icon={faAngleLeft} color="#0064d2" />
                <span className="text-[16px]">Quay lại</span>{" "}
              </Button>
              <Button
                className="w-full h-[50px] bg-[#0064d2]  text-white"
                loading={loadingBooking}
                onClick={() => {
                  formRef.current.submit();
                }}
              >
                <span className="text-[16px]">Thanh toán</span>{" "}
                <FontAwesomeIcon icon={faAngleRight} color="white" />
              </Button>
            </div>
          )
        }
        height={"100%"}
      >
        <Spin tip="Tải dữ liệu" size="large" spinning={loading}>
          {step === 1 ? (
            <div className="pb-[18px]">
              {hotelSelected.images && hotelSelected.images.length > 0 ? (
                <div className="slider-container mb-[32px]">
                  <Slider
                    className="slider-main"
                    asNavFor={sliderRef2.current}
                    ref={sliderRef1}
                  >
                    {hotelSelected.images.map((slide, index) => (
                      <div key={index}>
                        <img src={slide.origin} className="w-full" alt="" />
                      </div>
                    ))}
                  </Slider>
                  <Slider
                    asNavFor={sliderRef1.current}
                    ref={sliderRef2}
                    slidesToShow={4}
                    className="slider-thumbnails"
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                  >
                    {hotelSelected.images.map((slide, index) => (
                      <div key={index}>
                        <img
                          src={slide.origin}
                          className="w-full h-auto"
                          alt=""
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}
                >
                  <img
                    src={hotelSelected.thumbnail.origin}
                    alt=""
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-[12px]"
                  />
                </div>
              )}

              <div className="p-3">
                <div className="flex gap-3 items-center">
                  <span className="uppercase text-[#191919] text-[12px] font-semibold">
                    {hotelSelected?.category?.name}
                  </span>
                  <div className="flex gap-1 items-center">
                    {_.range(1, hotelSelected?.rating?.starNumber + 1).map(
                      (s) => (
                        <FontAwesomeIcon
                          key={s}
                          icon={faStar}
                          color="#f6d738"
                          style={{
                            fontSize: 18,
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
                <h3 className="text-[18px] mt-3 font-bold text-[#15284a]">
                  {hotelSelected.name}
                </h3>
                <hr className="my-2" />
                <div
                  className="flex h-[80px] mt-[24px] cursor-pointer"
                  onClick={() => setOpenViewMap(true)}
                  style={{
                    backgroundImage: `url(/images/bg_map_address_hotel.svg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <div className="pr-[100px] pl-[20px] pt-[10px] text-[#15284a]">
                    {hotelSelected.address.address}
                  </div>
                </div>
                <div className="text-[#0064D2] text-[14px] text-left flex gap-2 my-2">
                  <span>Checkin: {hotelSelected?.checkin?.beginTime}</span>{" "}
                  <span> - </span>
                  <span>Checkout: {hotelSelected?.checkout?.time}</span>
                </div>
                <div className="text-[16px] text-[#15284a] font-semibold mt-4">
                  Giới thiệu về khách sạn
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: expandedDes
                      ? hotelSelected.description
                      : hotelSelected.description.slice(0, 300) + "...",
                  }}
                  className="text-gray-700 duration-300 my-1"
                />
                <div onClick={toggleExpand} className="text-center my-4">
                  <span className="text-[#0064d2]">
                    {" "}
                    {expandedDes ? "Thu gọn" : "Xem thêm"}
                  </span>
                </div>
              </div>
              <div className="bg-white h-[40px] text-center flex items-center justify-center">
                <h3 className="text-[#0064d2] font-bold uppercase">
                  Danh sách phòng
                </h3>
              </div>
              {hotelSelected.rooms.length > 0 &&
                hotelSelected.rooms.map((room, index) => (
                  <>
                    <RoomItem
                      indexRoom={index + 1}
                      key={room.id}
                      addPrice={addPrice}
                      setRoomRateSelected={(rate, room) => {
                        CheckProductAvailability(rate, room);
                      }}
                      data={room}
                    />
                    <Divider className="my-4" />
                  </>
                ))}
              <div className="text-center mt-4">
                <span className="text-[#0064D2] font-semibold">
                  Hotline hỗ trợ khách hàng:{" "}
                </span>
                <a
                  href="tel:0876100800"
                  className="font-bold text-[#0064D2] text-[16px]"
                >
                  0876.100.800
                </a>
              </div>
              <Modal
                title={hotelSelected.name}
                open={openViewMap}
                closable={true}
                footer={null}
                className="modal-view-map"
                closeIcon={
                  <FontAwesomeIcon
                    icon={faClose}
                    color="#0064d2"
                    className="w-full"
                    style={{ fontSize: 24 }}
                    onClick={() => setOpenViewMap(false)}
                  />
                }
              >
                <iframe
                  width="100%"
                  height="450"
                  title="hotel-map"
                  style={{ border: "0" }}
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBAaCzG3gF-9CgHyXApQaVpaQtOANYyRNI&q=${hotelSelected.address.coordinate.latitude},${hotelSelected.address.coordinate.longitude}`}
                ></iframe>
              </Modal>
            </div>
          ) : (
            <div className="p-4">
              <Form
                layout="vertical"
                form={form}
                ref={formRef}
                onFinish={handleBooking}
                onFinishFailed={(values) => {
                  notification.error({
                    message: "Thông báo",
                    description: "Vui lòng nhập đầy đủ thông tin",
                  });
                }}
                autoComplete="off"
                className="form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onValuesChange={handleValueChanges}
              >
                <div className="flex justify-between mb-3">
                  <span className="font-semibold text-[16px] text-primary">
                    1. Thông tin liên hệ
                  </span>
                  <Checkbox
                    checked={isPersonChecking}
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setIsPersonChecking(true);
                      } else {
                        setIsPersonChecking(false);
                      }
                    }}
                  >
                    Là người nhận phòng
                  </Checkbox>
                </div>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="contactFirstName"
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Họ không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Họ" className="h-12" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="contactLastName"
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Tên đệm và tên không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Tên đệm và tên" className="h-12" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="contactPhone"
                      rules={[
                        {
                          required: true,
                          message: "Số điện thoại không được để trống",
                        },
                        {
                          pattern: /^(03|05|07|08|09)\d{8}$|^\+84\d{9}$/,
                          message: "Số điện thoại không hợp lệ",
                        },
                      ]}
                      className="mb-4"
                    >
                      <Input placeholder="SĐT: 0912345678" className="h-12" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="contactEmail"
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Email không được để trống",
                        },
                        {
                          type: "email",
                          message: "Vui lòng nhập email đúng định dạng",
                        },
                      ]}
                    >
                      <Input placeholder="Email" className="h-12" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="font-semibold text-[16px] text-primary mb-3">
                  2. Thông tin khách nhận phòng
                </div>

                <Row gutter={12}>
                  {/* <Col span={24} className="mb-1">
                    <div className="flex justify-between">
                      <span className="font-semibold text-[12px]">
                        Người lớn
                      </span>
                    </div>
                  </Col> */}
                  <Col
                    className="mb-1"
                    span={12}
                  >
                    <Form.Item
                      name={`first_name_adt`}
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Họ không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Họ" className="h-12" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={`last_name_adt`}
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Tên đệm và tên không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Tên đệm và tên" className="h-12" />
                    </Form.Item>
                  </Col>
                  {/* {formSearch.getFieldValue("numRooms") > 1 && (
                    <Col span={8}>
                      <Form.Item name={`room_index_adt`} className="mb-0">
                        <Select
                          placeholder="Số phòng"
                          className="h-12 bg-[#f5f6f6] font-bold"
                        >
                          {_.range(
                            1,
                            formSearch.getFieldValue("numRooms") + 1
                          ).map((room) => (
                            <Select.Option key={room} value={room}>
                              <span className="font-semibold">
                                {" "}
                                Phòng {room}
                              </span>
                            </Select.Option>
                          ))}
                          <Select.Option key={0} value={0}>
                            <span className="font-semibold">Tự sắp xếp</span>
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )} */}
                </Row>
                {/* {formSearch.getFieldValue("numAdults") > 0 &&
                  _.range(1, formSearch.getFieldValue("numAdults") + 1).map(
                    (item, index) => (
                      <Row gutter={12} key={index}>
                        <Col span={24} className="mb-1">
                          <div className="flex justify-between">
                            <span className="font-semibold text-[12px]">
                              Người lớn
                            </span>
                            <Checkbox
                              checked={item === adtChoosed}
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setAdtChoosed(item);
                                } else {
                                  setAdtChoosed(null);
                                }
                              }}
                            >
                              Là người liên hệ
                            </Checkbox>
                          </div>
                        </Col>
                        <Col
                          className="mb-1"
                          span={
                            formSearch.getFieldValue("numRooms") > 1 ? 8 : 12
                          }
                        >
                          <Form.Item
                            name={`first_name_adt_${item}`}
                            className="mb-4"
                            rules={[
                              {
                                required: true,
                                message: "Họ không được để trống",
                              },
                            ]}
                          >
                            <Input placeholder="Họ" className="h-12" />
                          </Form.Item>
                        </Col>
                        <Col
                          span={
                            formSearch.getFieldValue("numRooms") > 1 ? 8 : 12
                          }
                        >
                          <Form.Item
                            name={`last_name_adt_${item}`}
                            className="mb-4"
                            rules={[
                              {
                                required: true,
                                message: "Tên đệm và tên không được để trống",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Tên đệm và tên"
                              className="h-12"
                            />
                          </Form.Item>
                        </Col>
                        {formSearch.getFieldValue("numRooms") > 1 && (
                          <Col span={8}>
                            <Form.Item
                              name={`room_index_adt_${item}`}
                              className="mb-0"
                            >
                              <Select
                                placeholder="Số phòng"
                                className="h-12 bg-[#f5f6f6] font-bold"
                              >
                                {_.range(
                                  1,
                                  formSearch.getFieldValue("numRooms") + 1
                                ).map((room) => (
                                  <Select.Option key={room} value={room}>
                                    <span className="font-semibold">
                                      {" "}
                                      Phòng {room}
                                    </span>
                                  </Select.Option>
                                ))}
                                <Select.Option key={0} value={0}>
                                  <span className="font-semibold">
                                    Tự sắp xếp
                                  </span>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    )
                  )}

                {formSearch.getFieldValue("numChilds") > 0 &&
                  _.range(1, formSearch.getFieldValue("numChilds") + 1).map(
                    (item, index) => (
                      <Row gutter={12} key={index}>
                        <Col span={24}>
                          <span className="font-semibold text-[12px]">
                            Trẻ em
                          </span>
                        </Col>
                        <Col
                          span={
                            formSearch.getFieldValue("numRooms") > 1 ? 8 : 12
                          }
                        >
                          <Form.Item
                            name={`first_name_chd_${item}`}
                            className="mb-4"
                            rules={[
                              {
                                required: true,
                                message: "Họ không được để trống",
                              },
                            ]}
                          >
                            <Input placeholder="Họ" className="h-12" />
                          </Form.Item>
                        </Col>
                        <Col
                          span={
                            formSearch.getFieldValue("numRooms") > 1 ? 8 : 12
                          }
                        >
                          <Form.Item
                            name={`last_name_chd_${item}`}
                            className="mb-4"
                            rules={[
                              {
                                required: true,
                                message: "Tên đệm và tên không được để trống",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Tên đệm và tên"
                              className="h-12"
                            />
                          </Form.Item>
                        </Col>
                        {formSearch.getFieldValue("numRooms") > 1 && (
                          <Col span={8}>
                            <Form.Item
                              name={`room_index_chd_${item}`}
                              className="mb-0"
                            >
                              <Select
                                placeholder="Số phòng"
                                className="h-12 bg-[#f5f6f6] font-bold"
                              >
                                {_.range(
                                  1,
                                  formSearch.getFieldValue("numRooms") + 1
                                ).map((room) => (
                                  <Select.Option key={room} value={room}>
                                    <span className="font-semibold">
                                      {" "}
                                      Phòng {room}
                                    </span>
                                  </Select.Option>
                                ))}
                                <Select.Option key={0} value={0}>
                                  <span className="font-semibold">
                                    Tự sắp xếp
                                  </span>
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    )
                  )} */}
                {/* <Form.Item
                  name="occupancyPerRoom"
                  className="mb-4"
                  rules={[
                    {
                      required: true,
                      message: "Số lượng người ở tối đa 1 phòng",
                    },
                    {
                      validator: (_, value) =>
                        value && value >= 1
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Số lượng tối thiểu là 1")
                            ),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Số lượng người ở tối đa 1 phòng"
                    className="h-11 w-full"
                    min={1}
                  />
                </Form.Item> */}

                <Checkbox
                  size="large"
                  className="mb-3"
                  onChange={(e) => setInvoice(e.target.checked)}
                >
                  Xuất hoá đơn
                </Checkbox>

                {invoice && (
                  <>
                    <div className="self-start text-[16px] my-2 font-semibold text-primary">
                      3. Thông tin xuất hoá đơn
                    </div>

                    <Row gutter={12}>
                      <Col span={12} className="mb-3">
                        <Form.Item
                          name="VatMst"
                          className="mb-0"
                          rules={[
                            {
                              required: true,
                              message: "Mã số thuế không được để trống",
                            },
                            {
                              validator: async (_, value) => {
                                if (!value) {
                                  setInvoice("");
                                  return Promise.resolve(); // Bỏ qua nếu trường rỗng (đã được kiểm tra ở rule "required")
                                }
                                // Sử dụng Promise để trả về kết quả
                                return new Promise((resolve, reject) => {
                                  // Gọi hàm debounce và truyền callback xử lý kết quả
                                  debouncedCheckMst(value, (error) => {
                                    if (error) {
                                      reject(error); // Reject nếu có lỗi
                                    } else {
                                      resolve(); // Resolve nếu thành công
                                    }
                                  });
                                });
                              },
                            },
                          ]}
                        >
                          <Input className="h-12" placeholder="Mã số thuế" />
                        </Form.Item>
                        <span className="text-[14px] uppercase text-green-600 mt-1">
                          {invoiceCode}
                        </span>
                      </Col>
                      <Col span={12} className="mb-3">
                        <Form.Item
                          name="VatFullName"
                          className="mb-0"
                          rules={[
                            {
                              required: true,
                              message: "Người nhận hoá đơn không được để trống",
                            },
                          ]}
                        >
                          <Input
                            className="h-12"
                            placeholder="Nhập người nhận hoá đơn"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Form.Item name="voucher" className="w-full mb-4">
                  <Input.Search
                    placeholder="Nhập mã giảm giá"
                    size="large"
                    enterButton={
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#0064D2",
                          borderColor: "#0064D2",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                        }}
                      >
                        Áp dụng
                      </Button>
                    }
                    allowClear
                    onChange={(e) => {
                      if (!e.target.value) {
                        setVoucherTxt("");
                        setVoucherValue(0);
                      }
                    }}
                    onSearch={applyVoucher}
                  />
                </Form.Item>
                {voucherTxt && (
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{ __html: voucherTxt }}
                  />
                )}
              </Form>
              <Row className="mb-2">
                <Col span={8}>
                  <div>Loại phòng: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right text-[16px] text-[#0064d2]">
                    {roomSelected.roomTypeName}
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={8}>
                  <div>Số phòng: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right">
                    {formSearch.getFieldValue("numRooms")}
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={8}>
                  <div>Người lớn: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right">
                    {" "}
                    {formSearch.getFieldValue("numAdults")}
                  </div>
                </Col>
              </Row>
              {formSearch.getFieldValue("numChilds") > 0 && (
                <Row className="mb-2">
                  <Col span={8}>
                    <div>Trẻ em: </div>
                  </Col>
                  <Col span={16}>
                    <div className="font-semibold text-right">
                      {" "}
                      {formSearch.getFieldValue("numChilds")}
                    </div>
                  </Col>
                </Row>
              )}

              <Row className="mb-2">
                <Col span={9}>
                  <div>Tổng tiền trước thuế: </div>
                </Col>
                <Col span={15}>
                  <div className="font-semibold text-right text-[16px]">
                    {" "}
                    {rateSelected.rateBeforeTaxTotalNew?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    đ
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col span={8}>
                  <div>Tổng tiền sau thuế: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right text-[16px]">
                    {" "}
                    {rateSelected.rateAfterTaxTotalNew?.toLocaleString(
                      "vi-VN"
                    )}{" "}
                    đ
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col span={8}>
                  <div>Giảm giá: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right text-[16px] text-green-500">
                    {" "}
                    {voucherValue.toLocaleString("vi-VN")} đ
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col span={8}>
                  <div>Tổng tiền: </div>
                </Col>
                <Col span={16}>
                  <div className="font-semibold text-right text-primary text-[24px]">
                    {" "}
                    {(
                      rateSelected.rateAfterTaxTotalNew - voucherValue
                    ).toLocaleString("vi-VN")}{" "}
                    đ
                  </div>
                </Col>
              </Row>

              <div className="p-2 bg-[#e7ffe5] text-[#7bc27b] rounded my-4">
                Vui lòng cung cấp email và số điện thoại Việt Nam của người đặt
                phòng.
              </div>
            </div>
          )}
        </Spin>
      </Drawer>
    </>
  );
};

export default ChooseHotel;
